<template>
  <div class="pixel__layers-wrap">
    <label
      v-bind:class="{'pixel__layer--active': layer.id === currentLayer.id}"
      :for="`layer-${layer.id}`"
      class="pixel__layer"
      v-for="(layer, index) in layers"
      :key="layer.id"
      @click.stop="setCurrentLayer(index)"

    >
      <v-menu
        top :offset-x="true"
        transition="slide-x-transition"
        bottom
        right
        :key="index"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            dark
            icon
            v-on="on"
            class="pixel__context-menu"
          >
            <i class="fas fa-ellipsis-v"
               style="width: 16px;height: 16px;color:black;font-size: 14px;"></i>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item-group>
            <v-list-item @click="moveLayer(index, 'up')" :disabled="index == 0">
              <v-list-item-icon>
                <i class="fas fa-sort-up"></i>
              </v-list-item-icon>
              <v-list-item-content>
                {{ $t("designer.layer_move_up") }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="moveLayer(index, 'down')" :disabled="index == layers.length - 1">
              <v-list-item-icon>
                <i class="fas fa-sort-down"></i>
              </v-list-item-icon>
              <v-list-item-content>
                {{ $t("designer.layer_move_down") }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="removeLayer(index)" :disabled="layers.length == 1">
              <v-list-item-icon>
                <i class="fas fa-trash-alt"></i>
              </v-list-item-icon>
              <v-list-item-content>{{ $t("common.delete") }}</v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <span class="pixel__hide" v-bind:class="{hidden: !layer.visible}"
            @click.stop.prevent="toggleLayer(index)">
        <i class="fas fa-eye" style="width: 16px;height: 16px;color:black;font-size: 14px;"></i>
            </span>
      <div class="pixel__layer-preview" v-if="layer.preview_url">
        <img :src="layer.preview_url"/>
      </div>
    </label>
    <div class="pixel__add-layer-btn" @click="addLayer">+</div>
  </div>
</template>
<script>
import {mapGetters, mapState} from 'vuex';

export default {
  name: "Layers",
  computed: {
    ...mapState('design', ['project']),
    ...mapGetters('design', ['currentLayer']),
    layers() {
      return this.project.layers;
    },
  },
  methods: {
    moveLayer(index, direction) {
      this.$store.commit('design/changeLayerIndex', {
        layerIndex: index,
        direction: direction
      })
    },
    addLayer() {
      this.$store.commit('design/addLayer');
    },
    removeLayer(index) {
      this.$store.commit('design/removeLayer',index);
    },
    setCurrentLayer(index) {
      this.$store.commit('design/setCurrentLayer',index);
    },
    toggleLayer(index) {
      this.$store.commit('design/toggleLayerVisibility',index);
    },
  },
}
</script>


<style lang="scss" scoped>
.pixel {
  &__layers {
    position: fixed;
    padding: 16px;
    width: 200px;
    top: 0;
    left: 0;
    padding-top: 80px;
    z-index: 2;
    height: 80px;
    display: flex;
    box-shadow: 0 0 2px 3px rgba(0, 0, 0, .05);
    align-items: center;
  }

  &__remove, &__hide {
    position: absolute;
    padding: 10px;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #d7ccc8;
    justify-content: center;
    transform: rotate(45deg);
    right: 0;
    top: 0;
    opacity: 0;
  }

  &__hide {
    top: auto;
    bottom: 0;
    transform: none;
    width: auto;
    height: auto;
    padding: 2px;
    cursor: pointer;
    z-index: 3;
    opacity: 0.8;
    transition: opacity .2s ease-in-out;

    &.hidden {
      opacity: .2;
    }
  }

  &__context-menu {
    position: absolute;
    display: flex;
    align-items: center;
    font-size: 24px;
    justify-content: center;
    right: 0;
    top: 2px;
    transform: none;
    width: 18px;
    height: 18px;
    padding: 2px;
    cursor: pointer;
    z-index: 3;
  }

  &__layer {
    margin-bottom: 8px;
    border: 1px solid #d7ccc8;
    border-radius: 6px;
    display: flex;
    height: 60px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 0 8px;

    &:hover {
      .pixel__remove {
        opacity: 1;
      }
    }

    &--active {
      background: rgba(#d7ccc8, 0.5);
    }

    img {
    }

    input {
      flex: 1 1 auto;
      outline: none;
      font-size: 12px;
      cursor: pointer;
      text-align: left;
    }
  }

  &__navbar-item {
    margin-left: 16px;
    display: flex;
    align-items: center;

    &:first-child {
      margin-right: 20px;
    }
  }

  &__navbar-btn {
    border-radius: 6px;
    border: 1px solid #fff;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
    outline: none;

    svg path {
      transition: all .2s ease-in-out;
    }

    &:hover {
      background: rgba(#fff, .5);
    }

    &.active {
      background: #fff;
    }
  }

  &__layers-wrap {
    display: flex;
    flex-direction: column;
  }

  &__add-layer-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 44px;
    color: #d7ccc8;
    width: 100%;
    height: 60px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px dashed #d7ccc8;
    transition: all .2s ease-in-out;
    margin-bottom: 20px;

    &:hover {
      background: #d7ccc8;
      color: #fff;
    }
  }

  &__layer-preview {
    width: 80px;
    height: 56px;
    //flex: 0 0 auto;
    border-radius: 3px;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    margin-right: 10px;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 2px;
    }

    & + input {
      max-width: 55px;
    }
  }
}
</style>
